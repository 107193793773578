import LocalDB from "./LocalDB";
import { getHttpClient } from "./modules/http/http-client";

declare function GetTreeScroller(tree: DevExpress.ui.dxTreeView): DevExpress.ui.dxScrollable;

interface External {
    ConsoleLog: Function;
}

class GetFileResponse {
    Blob: string;
}

class ascommon {
    static isCore: boolean;
    static isPrint: boolean;
    static token: string;
    static AllowFileLogging: boolean;
    static EncryptLogs: boolean;
    static IsDebugMode: boolean;

    static ajax(url: string, params: object, successCallback: Function, errorCallback: Function = null, method: string = "POST", async: boolean = true, headers: object = {}) {
        $.extend(headers, {'X-AS-Session-ID': window.sessionID});
        let ajax: any = {
            url: url,
            type: method,
            data: url.startsWith('/api') ? JSON.stringify(params) : params,
            cache: false,
            async: async,
            headers: headers,
            contentType: url.startsWith('/api') ? 'application/json' : undefined,
            dataType: url.startsWith('/api') ? 'json' : undefined,
            success(result) {
                if (successCallback)
                    successCallback(result);
            },
            error(result) {
                if (errorCallback) {
                    errorCallback(result);
                }

                console.log("AJAX ERROR: " + JSON.stringify(result));
            },
            complete(result) {
                if (window && window.hideBusyIndicator) window.hideBusyIndicator();
            }
        }
        if (ascommon.isCore)
            ajax.beforeSend = (request) => {
                request.setRequestHeader("Authorization",
                    "Bearer " + ascommon.token);
            }
        if (ascommon.isPrint) {
            ajax.processData = false;
            ajax.contentType = false;
            ascommon.isPrint = false;
        }
        $.ajax(ajax);
    }

    static async get<RequestType>(
        url: string,
        params: object,
        successCallback: Function = null,
        errorCallback: Function = null,
        finallyCallback: Function = null
    ) {
        const client = await getHttpClient();
        await client.get<RequestType>(
            url, params)
            .then(function (response) {
                if (successCallback) {
                    successCallback(response);
                }
            })
            .catch(function (error) {
                if (errorCallback) {
                    let override = errorCallback(error);
                    // if you want complete control over error handling return a true value from your error callback
                    // but if you just have some extra steps you want to layer on top of the default error actions
                    // forgo a specified return value
                    if (override === true) {
                        return;
                    }
                }

                // default error handling
                ascommon.log(`API error: ${error.code}\nMessage: ${error.message}`);
            })
            .finally(function (response) {
                if (finallyCallback) {
                    let override = finallyCallback(response);

                    if (override === true) {
                        return;
                    }
                }

                // default final handling
                if (window && window.hideBusyIndicator) {
                    window.hideBusyIndicator();
                }
            });
    }

    static async post<RequestType>(
        url: string,
        params: object,
        successCallback: Function = null,
        errorCallback: Function = null,
        finallyCallback: Function = null
    ) {
        const client = await getHttpClient();
        await client.post<RequestType>(
            url, params)
            .then(function (response) {
                if (successCallback) {
                    successCallback(response);
                }
            })
            .catch(function (error) {
                if (errorCallback) {
                    let override = errorCallback(error);
                    // if you want complete control over error handling return a true value from your error callback
                    // but if you just have some extra steps you want to layer on top of the default error actions
                    // forgo a specified return value
                    if (override === true) {
                        return;
                    }
                }

                // default error handling
                ascommon.log(`API error: ${error.code}\nMessage: ${error.message}`);
            })
            .finally(function (response) {
                if (finallyCallback) {
                    let override = finallyCallback(response);

                    if (override === true) {
                        return;
                    }
                }

                // default final handling
                if (window && window.hideBusyIndicator) {
                    window.hideBusyIndicator();
                }
            });
    }

    static async GetFile(type: string, fileName: string) {
        ascommon.get<GetFileResponse>(
            `/admin-tools/get-file/${type}/${fileName}/`,
            { responseType: "blob" },
            (response) => {
                const a = document.createElement("a");
                document.body.appendChild(a);
                a.style.display = "none";

                const blob = new Blob([response.data], { type: "application/octet-stream" });
                const url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = fileName;
                a.click();

                // Clean up
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            });
    }

    static Clone(originalObject, circular) {
        // http://blog.soulserv.net/understanding-object-cloning-in-javascript-part-ii/
        // First create an empty object with
        // same prototype of our original source

        let propertyIndex,
            descriptor,
            keys,
            current,
            nextSource,
            indexOf,
            copies = [{
                source: originalObject,
                target: Object.create(Object.getPrototypeOf(originalObject))
            }],
            cloneObject = copies[0].target,
            sourceReferences = [originalObject],
            targetReferences = [cloneObject];

        // First in, first out
        while (current = copies.shift()) {
            keys = Object.getOwnPropertyNames(current.source);

            for (propertyIndex = 0; propertyIndex < keys.length; propertyIndex++) {
                // Save the source's descriptor
                descriptor = Object.getOwnPropertyDescriptor(current.source, keys[propertyIndex]);

                if (!descriptor.value || typeof descriptor.value !== 'object') {
                    Object.defineProperty(current.target, keys[propertyIndex], descriptor);
                    continue;
                }

                nextSource = descriptor.value;
                descriptor.value = Array.isArray(nextSource) ?
                    [] :
                    Object.create(Object.getPrototypeOf(nextSource));

                if (circular) {
                    indexOf = sourceReferences.indexOf(nextSource);

                    if (indexOf !== -1) {
                        // The source is already referenced, just assign reference
                        descriptor.value = targetReferences[indexOf];
                        Object.defineProperty(current.target, keys[propertyIndex], descriptor);
                        continue;
                    }

                    sourceReferences.push(nextSource);
                    targetReferences.push(descriptor.value);
                }

                Object.defineProperty(current.target, keys[propertyIndex], descriptor);

                copies.push({ source: nextSource, target: descriptor.value });
            }
        }
        return cloneObject;
    }

    static DALExcecute(dalMethod: string, inpParams: any, callBack: Function) {
        ascommon.ajax("/Worklist/DALExcecute",
            {
                dalMethod: dalMethod,
                inpParams: inpParams
            },
            callBack);
    }

    static log(msg: string): void {
        let dt: string = self.moment
            ? self.moment().format('DD-HH:mm:ss.SSS')
            : "";

        let stackTrace: string = "";
        if (ascommon.IsDebugMode) {
            stackTrace = Error().stack.substring(7); // trim 'Error\n'
            stackTrace = stackTrace.substring(stackTrace.indexOf("\n")); // remove the ascommon.log stack frame as its redundant
        }

        if (self.boundLogger)
            //console log for chromium launcher
            self.boundLogger.sendConsoleCommand(`${dt}> ${msg}${stackTrace}`);
        else {
            //console log for browser
            if (ascommon.IsDebugMode) {
                // using groupCollapsed here so you can easily see the messages then expand to get the stack trace
                console.groupCollapsed(msg);
                console.trace();
                console.groupEnd();
            }
            else {
                console.log(`${dt}> ${msg}`);
            }
        }

        if (typeof LocalDB !== 'undefined' && ascommon.AllowFileLogging) {
            if (ascommon.EncryptLogs) {
                msg = EncrDecrService.Encript(msg);
            }
            LocalDB.MessagePut(dt, ascommon.EncryptLogs
                ? EncrDecrService.Encript(`${msg}${stackTrace}`)
                : `${msg}${stackTrace}`);
        }
    }

    static getKeyByValue(object, value) {
        for (var prop in object) {
            if (object.hasOwnProperty(prop)) {
                if (object[prop] === value)
                    return prop;
            }
        }
    }
}
export default ascommon;
// for main thread this is window, for workers this is the WorkerGlobalScope
self.ascommon = ascommon;
