import angular from "angular";
import ascommon from "../../ascommon";
import { ChangePasswordModel } from "./login-models";

angular.module('LoginModule').controller('changePasswordController', ['$scope', 'loginUtils', async function($scope, loginUtils){
    $scope.chgPwdLoading = false;
    $scope.autoFocus = false;

    const urlParams = new URLSearchParams(window.location.search);
    $scope.errors = [...urlParams.getAll('ErrorMessages')];
    $scope.data = {
        returnUrl: loginUtils.getReturnUrl(),
        username: urlParams.get("username")
    } as ChangePasswordModel;

    window.addEventListener('load', () => {
        loginUtils.setFingerprint($scope, $scope.data);
        $scope.autoFocus = !$scope.autoFocus;
    });

    $scope.changePassword = async function () {
        $scope.chgPwdLoading = true;

        $scope.errors = [];

        let data = $scope.data;
        data.clientTimezoneOffset = new Date().getTimezoneOffset();

        ascommon.post<ChangePasswordModel>(
            "/change-password",
            data,
            (result) => {
                var urlParams = new URLSearchParams();
                urlParams.set("fingerprint", data.fingerprint);
                urlParams.set("returnUrl", data.returnUrl);
                urlParams.set("clientTimezoneOffset", data.clientTimezoneOffset.toString());

                return window.location.href = "/LoginNotifications?" + urlParams.toString();
            },
            (error) => {
                switch (error.response.status) {
                    case 409: // serious error redirect to login page
                        var urlParams = new URLSearchParams(
                            error.response.data.ErrorMessages.map(m => ['ErrorMessages', m])
                        );
                        urlParams.set("returnUrl", data.returnUrl);

                        return window.location.href = "/Login?" + urlParams.toString();
                    case 401: // initial password was bad so init field
                        $scope.data.password = "";
                        break;
                }

                $scope.data.newPassword = "";
                $scope.data.newPasswordConfirm = "";
                $scope.errors = error.response.data.ErrorMessages;
                $scope.$apply();

                $scope.autoFocus = !$scope.autoFocus;
            },
            (result) => {
                $scope.chgPwdLoading = false;
                $scope.$apply();
            }
        );
    };
}]);
