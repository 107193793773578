// https://github.com/jakearchibald/idb
// https://www.npmjs.com/package/idb

class LocalDB {
    static localDB;
    static async Open() {
        // You do not need to close connection
        LocalDB.localDB = await idb.openDB('localDB', 1, {
            upgrade(db) {
                db.createObjectStore('LogMessage', { keyPath: 'ID' });
            },
        });
    }

    static async MessagePut(id:string, message: string) {
        if (!LocalDB.localDB)
            await LocalDB.Open();
        let objectStore = LocalDB.localDB.transaction("LogMessage", "readwrite").objectStore("LogMessage");
        const user = typeof window !== 'undefined' && window.activeUser ? window.activeUser.UserID : "worker";
        objectStore.put({ ID: id, userID: user, message: message});
    }

    static async MessagesCount(){
        if (!LocalDB.localDB)
            await LocalDB.Open();
        const count = await LocalDB.localDB.count("LogMessage");
        return count;
    }

    static async MessagesGet(count: number) {
        if (!LocalDB.localDB)
            await LocalDB.Open();
        let messages = [];
        let ii = 0;
        let cursor = await LocalDB.localDB.transaction("LogMessage").store.openCursor();
        while (cursor) {
            const rec = cursor.value;
            rec.message = btoa(rec.message);
            messages.push(rec);
            cursor = await cursor.continue();
            if (ii++ > count) break;
        }
        return messages;
    }

    static async MessagesClear(count: number) {
        if (!LocalDB.localDB)
            await LocalDB.Open();
        let ii = 0;
        let cursor = await LocalDB.localDB.transaction("LogMessage", "readwrite").store.openCursor();
        while (cursor) {
            cursor.delete();
            cursor = await cursor.continue();
            if (ii++ > count) break;
        }
    }
}

export default LocalDB;
// for main thread this is window, for workers this is the WorkerGlobalScope
self.LocalDB = LocalDB;
