import angular from "angular";
import ascommon from "../../ascommon";
import { LoginModel } from "./login-models";

angular.module('LoginModule').controller('loginController', ['$scope', 'loginUtils', async function($scope, loginUtils){
    $scope.loginLoading = false;
    $scope.samlLoading = false;
    $scope.autoFocus = false;

    const urlParams = new URLSearchParams(window.location.search);
    $scope.errors = [...urlParams.getAll('ErrorMessages')];
    $scope.data = {
        returnUrl: loginUtils.getReturnUrl()
    } as LoginModel;

    window.addEventListener('load', () => {
        loginUtils.setFingerprint($scope, $scope.data);
        $scope.autoFocus = !$scope.autoFocus;
    });

    $scope.standardSignIn = async function () {
        $scope.loginLoading = true;

        $scope.errors = [];

        let data = $scope.data;
        data.clientTimezoneOffset = new Date().getTimezoneOffset();

        ascommon.post<LoginModel>(
            "/login",
            data,
            (result) => {
                let urlParams = new URLSearchParams();
                urlParams.set("fingerprint", data.fingerprint);
                urlParams.set("returnUrl", data.returnUrl);
                urlParams.set("clientTimezoneOffset", data.clientTimezoneOffset.toString());

                return window.location.href = "/LoginNotifications?" + urlParams.toString();
            },
            (error) => {
                switch(error.response.status) {
                    case 401: // forced password change
                        let urlParams = new URLSearchParams(
                            error.response.data.ErrorMessages.map(m => ['ErrorMessages', m])
                        );
                        urlParams.set("returnUrl", data.returnUrl);
                        urlParams.set("username", data.username);

                        return window.location.href = "/ChangePassword?" + urlParams.toString();
                }

                $scope.data.password = "";
                $scope.errors = error.response.data.ErrorMessages;
                $scope.$apply();

                $scope.autoFocus = !$scope.autoFocus;
            },
            (result) => {
                $scope.loginLoading = false;
                $scope.$apply();
            }
        );
    };

    $scope.samlLogin = function () {
        $scope.samlLoading = true;
        loginUtils.setTimezoneOffset($scope.data);
    }
}]);
