import FingerPrint2 from "fingerprintjs2";
import angular from "angular";
import { LoginModel, ChangePasswordModel } from "./login-models";

angular.module('LoginModule', []);

/*
A password based input with a button to toggle the visibility of the text.
 */
angular.module('LoginModule').directive('showHideInput', function() {
    return {
        scope: {
            options: "<",
            binding: "="
        },
        template: ` <div class="form-group" style="display: flex; flex-direction: column">
                        <div style="text-align:left">
                            <label for="{{options.name}}">{{options.placeholder}}</label>
                        </div>
                        <div style="display: flex; flex-direction: row; align-items: center;">
                            <div style="width: 100%;">
                                <input id="{{options.id}}" ng-model="binding" name="{{options.name}}" class="form-control focusable" type="password" autocomplete="{{options.autoComplete}}" placeholder="{{options.placeholder}}"/>
                            </div>
                            <div>
                                <div data-state="show" style="display: none; background: none">
                                    <img class="showHideImg" src="/Resources/Icons/ShowPasswordBlack.png" alt="show password" style="margin-left: -30px;" />
                                </div>
                            </div>
                        </div>
                    </div>`,
        link: function ( scope, element, attrs ) {
            var show = false;

            let input = element.find('input');
            let img = element.find('img');
            let button = img.parent();

            input.on('change keyup', function () {
                if (input.val()) button.css('display', 'unset');
                else button.css('display', 'none');
            });

            button.on('click', function (e) {
                show = !show;

                if (show) {
                    input.attr('type', 'text');
                    img.attr('src', '/Resources/Icons/HidePasswordBlack.png');
                    img.attr('alt', 'hide Password');
                    button.data('state', 'hide');
                }
                else {
                    input.attr('type', 'password');
                    img.attr('src', '/Resources/Icons/ShowPasswordBlack.png');
                    img.attr('alt', 'show Password');
                    button.data('state', 'show');
                }
            });
        }
    }
});

/*
Looks for children inputs/textareas, and sets focus to the first empty field.
Uses a trigger which is just a boolean flag you can flip whenever you want to engage the focus.
 */
angular.module('LoginModule').directive('dynamicInputFocus', function() {
    return {
        scope: {
            trigger: "<",
        },
        link: function ( scope, element, attrs ) {
            let inputs = element.find('input');
            let textareas = element.find('textarea');

            scope.$watch("trigger", function () {
                for (let i = 0; i < inputs.length; i++) {
                    let input = inputs[i] as HTMLInputElement;

                    if (input.type === "hidden" || input.style["display"] === "none") {
                        continue;
                    }

                    if (!input.value) {
                        input.focus();
                        return;
                    }
                }

                for (let t = 0; t < textareas.length; t++) {
                    let textarea = textareas[t] as HTMLTextAreaElement;

                    if (textarea.type === "hidden" || textarea.style["display"] === "none") {
                        continue;
                    }

                    if (!textarea.value) {
                        textarea.focus();
                        return;
                    }
                }
            });
        }
    }
});

/*
A button that when when the observed boolean options.binding is true hides the button and replaces with a loading icon.
 */
angular.module('LoginModule').directive('loaderButton', function() {
    return {
        scope: {
            options: "<",
            data: "=",
            loginUtils: "="
        },
        template: ` <div ng-model="options.binding" style="display: flex; flex-direction: column; align-items: center;">
                        <button id="{{options.id}}" type="{{options.type}}" class="btn btn-block" ng-click="options.onClick()">{{options.text}}</button>
                        <div class="btn btn-block" style="display: none;" disabled>
                            <div class="loader-dynamic-small" style="margin-left: auto; margin-right: auto;"></div>
                        </div>
                    </div>`,
        link: function ( scope:any, element, attrs ) {
            let button = element.find('button');
            let img = element.children().find('div');

            scope.$watch("options.binding", function () {
                if (scope.options.binding) {
                    button.css("display", "none");
                    img.css("display", "");
                }
                else {
                    button.css("display", "");
                    img.css("display", "none");
                }
            });
        }
    }
});

angular.module('LoginModule').service('loginUtils', function () {
    class loginUtils {
        setFingerprint = async function(scope, data: LoginModel | ChangePasswordModel): Promise<void> {
            // From the docs https://github.com/fingerprintjs/fingerprintjs/tree/v2?tab=readme-ov-file#usage
            // Getting the fingerprint should be done after the page has finished loading in order to get
            // consistent results.

            // checks if the browser supports requestIdleCallback
            if (window.requestIdleCallback) {
                // waits for the browser to be idle before setting the fingerprint
                window.requestIdleCallback(async () => {
                    data.fingerprint = await this.getFingerprint();
                    scope.$apply();
                });
                return;
            }

            // delays setting the fingerprint by 500ms
            await new Promise(resolve => setTimeout(resolve, 500));
            data.fingerprint = await this.getFingerprint();
            scope.$apply();
        }

        getFingerprint = async function() : Promise<string> {
            const murmur = await FingerPrint2.getPromise().then((c) => FingerPrint2.x64hash128(c.map(x => x.value).join('')))
            const from = window.matchMedia('(display-mode: standalone)').matches ? (" (PWA)") : " (browser)";
            return murmur + from;
        }

        setTimezoneOffset = function(data: LoginModel | ChangePasswordModel) {
            data.clientTimezoneOffset = new Date().getTimezoneOffset();
        }

        getReturnUrl = function () {
            var urlParams = new URLSearchParams(window.location.search);

            var returnUrl = urlParams.get('returnUrl');

            if (returnUrl) {
                return returnUrl;
            }

            return "Worklist/WorklistView";
        }
    }

    return new loginUtils();
});
